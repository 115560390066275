import React from 'react';
import IconIsDefault from '../../images/icons/i-is-default.png';
import { useAPI } from '../../utils/api';
import { showLoading, dismissLoading } from '../../utils/loading';

const MemberItem = ({
  data,
  isDefault = false,
  tax = false,
  onEdit,
  onDelete,
}) => {
  const api = useAPI();

  const saveAddress = (addressId) => {
    showLoading();
    api
      .getCustomerAddresses()
      .then((res) => {
        dismissLoading();
      })
      .catch((error) => {
        dismissLoading();
        console.log(error);
      });
  };

  return (
    <>
      <div className="member-item">
        <header>
          {tax ? (
            <>
              <h2 className="title">統一編號: {data.uniformNumbers}</h2>
            </>
          ) : (
            <>
              <h2 className="name">{data.name}</h2>
              <div className="number">{data.phone}</div>
            </>
          )}
        </header>
        {tax ? (
          <>
            <div className="info">
              <div className="company">公司 : {data.companyName}</div>
              <div className="number">卡號 : {data.companyVIPnumber}</div>
            </div>
          </>
        ) : (
          <div className="address">{data.address}</div>
        )}

        <footer>
          <div className="buttons">
            <button
              className="edit"
              onClick={() => {
                onEdit();
              }}
            >
              編輯
            </button>
            <button
              className="delete"
              onClick={() => {
                onDelete();
              }}
            >
              刪除
            </button>
          </div>
        </footer>
      </div>

      <style jsx>
        {`
          .member-item {
            padding: 12px 19px 16px 26px;
            background: #ffffff;
            border: 1px solid #5fd2da;
            box-sizing: border-box;
            border-radius: 8px;

            margin-bottom: '20px';

            header {
              margin-bottom: 8px;
              display: flex;
              justify-content: space-between;
              align-items: baseline;

              .title {
                font-style: normal;
                font-weight: bold;
                font-size: 18px;
                line-height: 26px;
                color: #333333;
              }

              .name {
                font-style: normal;
                font-weight: bold;
                font-size: 18px;
                line-height: 26px;
                color: #333333;
              }

              .number {
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 23px;
                color: #333333;
              }
            }

            .info {
              font-style: normal;
              font-weight: normal;
              font-size: 16px;
              line-height: 23px;
              color: #333333;
              margin-bottom: 19px;

              .company {
                margin-bottom: 8px;
              }
            }

            .address {
              font-style: normal;
              font-weight: normal;
              font-size: 16px;
              line-height: 23px;
              color: #333333;
              margin-bottom: 30px;
              height: 46px;
            }

            footer {
              display: flex;
              justify-content: space-between;
              align-items: center;

              .buttons {
                display: grid;
                grid-template-columns: 1fr 1fr;
                column-gap: 17px;

                button {
                  background: #ffffff;
                  border: 1px solid #214a4d;
                  box-sizing: border-box;
                  border-radius: 30px;
                  font-size: 14px;
                  line-height: 20px;
                  text-align: right;
                  color: #214a4c;
                  height: 30px;
                  width: 60px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                }
              }
            }
          }
        `}
      </style>
    </>
  );
};

export default MemberItem;
